export const siteConfig = {
  name: "Tofu UI",
  url: "https://ui.sdjz.wiki",
  ogImage: "https://#/api/og",
  description:
    "Beautifully designed landing page components built with React & Tailwind CSS.",
  links: {
    twitter: "https://twitter.com/miaokezi",
    discord: "https://discord.gg/#",
    github: "https://github.com/shuakami",

    Shuakami:"https://github.com/shuakami",
    ByteFreeze:"https://docs.sdjz.wiki/",
    SdjzWiki:"https://sdjz.wiki/",

  },
  keywords: [
    "React",
    "Tailwind CSS",
    "Framer Motion",
    "Landing Page",
    "Components",
    "Next.js",
  ],
};

export type SiteConfig = typeof siteConfig;
