// config/docs.tsx
import { MainNavItem, SidebarNavItem } from "@/types";

interface DocsConfig {
  mainNav: MainNavItem[];
  sidebarNav: SidebarNavItem[];
}

const componentFiles = [
  "alert.mdx",
  "avatar.mdx",
  "badge.mdx",
  "bigpost.mdx",
  "button.mdx",
  "circular-progress.mdx",
  "data-table.mdx",
  "dropdown-menu.mdx",
  "footer.mdx",
  "header.mdx",
];


// 生成静态链接
const createHref = (filename: string): string => {
  const baseName = filename.split('.')[0].toLowerCase();
  return `/docs/components/${baseName}`;
};

// 生成侧边栏条目
const generateSidebarItems = () => {
  return componentFiles.map(filename => ({
    title: filename.split('.')[0].replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
    href: createHref(filename),
    items: [],
  }));
};

export const docsConfig: DocsConfig = {
  mainNav: [
    {
      title: "组件",
      href: "/components",
    },
  ],
  sidebarNav: [
    {
      title: "开始使用",
      items: [

        {
          title: "简介",
          href: "/docs",
          items: [],
        },
        {
          title: "安装",
          href: "/docs/installation",
          items: [],
        },
        {
          title: "CLI",
          href: "/docs/cli",
          items: [],
        },
      ],
    },
    {
      title: "组件",
      items: generateSidebarItems(),
    },
  ],
};
